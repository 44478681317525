<template>
	<v-text-field
		id="topSearchBar"
		v-focus-on-key
		v-if="placeholder != 'Search Seo-Analysis...'"
		ref="search"
		v-model.trim="listingSearch"
		:background-color="!isFocused ? 'grey lighten-3' : undefined"
		:placeholder="placeholder"
		autocomplete="off"
		hide-details
		outlined
		style="max-width: 450px; min-width: 400px"
		@focus="isFocused = true"
		@keydown.esc="onEsc"
		@keydown.enter="onEnter"
		@keydown.tab="onTab"
	>
		<!-- @blur="resetSearch" -->
		<template #prepend-inner>
			<v-icon :color="!isFocused ? 'grey' : undefined" class="ml-1 mr-2"> mdi-magnify </v-icon>
		</template>
	</v-text-field>
</template>

<script>
import { isEmpty } from "lodash";
import { mapGetters } from "vuex";
import { SET_SEARCH_MODEL } from "@/core/services/store/listing.module";

export const TopSearchBar = {
	name: "top-search-bar",
	data: () => ({
		isFocused: false,
		listingSearch: null,
	}),
	beforeUpdate() {
		document.addEventListener("keyup", (e) => {
			if (e.code === "Escape" || e.key === "Escape" || e.keyCode === 27 || e.which === 27) {
				this.onEsc();
			}
		});
	},
	methods: {
		onEsc() {
			this.resetSearch();
			this.$store.dispatch(SET_SEARCH_MODEL, null);
		},
		onEnter() {
			this.startSearch();
		},
		onTab() {
			this.startSearch();
		},
		startSearch() {
			this.$store.dispatch(SET_SEARCH_MODEL, this.listingSearch);
			//SearchEventBus.$emit("start-search", this.listingSearch);
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.listingSearch = null;
				_this.isFocused = false;
			});
			// this.$store.dispatch(SET_SEARCH_MODEL, null);
		},
	},
	beforeDestroy() {
		document.onkeydown = null;
		this.resetSearch();
		this.$store.dispatch(SET_SEARCH_MODEL, null);
		// SearchEventBus.$off("search-template");
	},
	beforeCreate() {
		//this.resetSearch();
	},
	mounted() {
		const _this = this;
		if (isEmpty(_this.$route.query) === false && _this.$route.query.search) {
			_this.listingSearch = _this.$route.query.search;
			_this.startSearch();
			_this.$refs.search.focus();
		}
	},
	computed: {
		...mapGetters(["searchTitle"]),
		placeholder() {
			return this.isFocused
				? "Start Typing then press enter"
				: "Search " + (this.searchTitle ? this.searchTitle : "") + "...  [Ctrl+B]";
		},

		isSearching() {
			return this.listingSearch && this.listingSearch.length > 0;
		},
	},
};

export default TopSearchBar;
</script>
