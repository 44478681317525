<template>
	<v-sheet
		class="recurring-main-listing"
		style="min-height: calc(100vh - 88px)"
		v-if="getPermission('reminder:view')"
	>
		<v-layout class="mt-2">
			<v-flex class="py-0 my-auto">
				<h3 class="mb-0">Reminder</h3>
			</v-flex>
			<!-- 	<v-spacer></v-spacer> -->
			<v-flex class="d-flex justify-content-end align-center pr-1">
				<div class="mr-2 fw-600 no-wrap" style="width: 60px">Filter By</div>
				<div class="filterTagSelect w-30 mr-2">
					<v-text-field
						right
						ref="search"
						v-model.trim="DataSearch"
						autocomplete="off"
						hide-details
						placeholder="search."
						clearable
						outlined
						@click:clear="onEsc"
						@keydown.esc="onEsc"
						@keydown.enter="filterReminder(reminderTabs)"
					>
					</v-text-field>
				</div>
				<div class="filterTagSelect w-30 mr-2">
					<v-autocomplete
						:items="moduleType"
						item-text="text"
						clearable
						item-value="value"
						v-model="module_type"
						:menu-props="{ bottom: true, offsetY: true }"
						prepend-inner-icon="mdi-tag"
						outlined
						hide-details
						class="filterSelect"
						placeholder="Select Module"
						content-class="filterSelectContent"
						@change="filterReminder(reminderTabs)"
					>
					</v-autocomplete>
				</div>
				<div class="filterTagSelect w-30 mr-2">
					<v-autocomplete
						:items="filterType"
						item-text="text"
						clearable
						item-value="value"
						:menu-props="{ bottom: true, offsetY: true }"
						prepend-inner-icon="mdi-tag"
						outlined
						v-model="date_type"
						hide-details
						class="filterSelect"
						placeholder="Filter"
						content-class="filterSelectContent"
						@click:clear="
							() => {
								//	if (date_type == 'today') {
								date_type = null;
								filterReminder(reminderTabs);
								//}
							}
						"
						@change="
							() => {
								(date_range = []), (date_status = Number(new Date()));
								//	if ($event == 'today') {
								filterReminder(reminderTabs);
								//}
							}
						"
					>
						<!-- @change="date_range = []" -->
					</v-autocomplete>
				</div>
				<div class="filterTagSelect w-30 mr-2">
					<DateRangePicker
						ref="dateRangePickerRef"
						:id="+new Date()"
						hide-details
						hide-top-margin
						clearable
						placeholder="Reminder"
						class="filterSelect"
						v-model="date_range"
						:clear="date_status"
						@click:clear="(date_range = null), filterReminder(reminderTabs)"
						content-class="filterSelectContent"
						@change="
							(event) => {
								if (event && event.length > 1) {
									date_type = null;
									filterReminder(reminderTabs);
								}
							}
						"
					></DateRangePicker>
					<!-- @change="date_type = null" -->
				</div>

				<!-- <v-btn
					color="blue darken-4 text-white"
					class="mr-2"
					depressed
					tile
					:disabled="pageLoading"
					min-width="34"
					width="34"
					v-on:click="filterReminder()"
				>
					<v-icon center> mdi-magnify </v-icon>
				</v-btn> -->

				<div v-if="isFilterApply()">
					<v-btn
						depressed
						color="white"
						class="red darken-4 mr-2"
						tile
						text
						v-on:click="clearFilter"
						small
						min-width="30"
						width="30"
						style="height: 26px; min-width: 0px !important"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<v-btn
					v-if="getPermission('reminder:create')"
					depressed
					tile
					class="red darken-4"
					color="white--text "
					@click="open_reminder_drawer()"
				>
					<v-icon class="mr-1">mdi-plus</v-icon>
					Create
				</v-btn>
			</v-flex>
		</v-layout>
		<v-layout class="page-summary mt-2" :class="summary_show ? 'show' : ''" style="gap: 8px">
			<PageSummeryBox
				color="red"
				text="Overdue"
				:count="overDue_count"
				@click="filterReminder('overdue')"
			/>
			<PageSummeryBox
				color="blue"
				text="Upcoming"
				:count="upcoming_count"
				@click="filterReminder('upcoming')"
			/>
			<PageSummeryBox
				color="green"
				text="Completed"
				:count="complete_count"
				@click="filterReminder('complete')"
			/>
		</v-layout>
		<v-layout class="px-2 py-2">
			<v-tabs
				v-model="reminderTabs"
				background-color="transparent"
				color="blue"
				class="custom-tab-transparent w-100"
				active-class="blue darken-4 text-white"
				hide-slider
				@change="changeTab"
			>
				<!-- ($event) => $event && filterReminder($event), -->
				<v-tab href="#overdue" class="v-tab">
					<v-icon small left>mdi-alarm-check</v-icon>
					Overdue ({{ overDue_count }})
				</v-tab>
				<v-tab href="#upcoming">
					<v-icon small left>mdi-alarm-check</v-icon>Upcoming ({{ upcoming_count }})</v-tab
				>
				<v-tab href="#complete">
					<v-icon small left>mdi-alarm-check</v-icon>Completed ({{ complete_count }})</v-tab
				>
			</v-tabs>
			<template v-if="selectedIds.length > 0">
				<v-btn
					depressed
					tile
					class="blue darken-4 float-right"
					color="white--text "
					@click="completeReminders"
				>
					Mark As Complete
				</v-btn>
				<v-btn
					depressed
					tile
					class="red darken-4 float-right ml-3"
					color="white--text "
					@click="
						() => {
							selectedIds = [];
						}
					"
				>
					<v-icon left>mdi-close</v-icon>
					Clear Selection
				</v-btn>
			</template>
		</v-layout>
		<div class="tab-layout-content px-4">
			<v-tabs-items v-model="reminderTabs">
				<v-tab-item value="overdue">
					<div class="overflow-y" style="height: calc(100vh - 290px)">
						<template v-if="pageLoading">
							<div class="py-15 col-md-6 mx-auto">
								<v-progress-linear indeterminate color="orange" height="8"></v-progress-linear>
							</div>
						</template>
						<template v-else>
							<ReminderTable
								:data="reminderData"
								status="overdue"
								@refresh="getReminder('overdue')"
								:selectedIds="selectedIds"
								v-on:selection="selectedData"
							/>
						</template>
					</div>
				</v-tab-item>
				<v-tab-item value="upcoming">
					<div class="overflow-y" style="height: calc(100vh - 290px)">
						<template v-if="pageLoading">
							<div class="py-15 col-md-6 mx-auto">
								<v-progress-linear indeterminate color="orange" height="8"></v-progress-linear>
							</div>
						</template>
						<template v-else>
							<ReminderTable
								:data="reminderData"
								status="upcoming"
								:selectedIds="selectedIds"
								@refresh="successCompleted()"
								v-on:selection="selectedData"
							/>
						</template>
					</div>
				</v-tab-item>
				<v-tab-item value="complete">
					<div class="overflow-y" style="height: calc(100vh - 290px)">
						<template v-if="pageLoading">
							<div class="py-15 col-md-6 mx-auto">
								<v-progress-linear indeterminate color="orange" height="8"></v-progress-linear>
							</div>
						</template>
						<template v-else>
							<ReminderTable :data="reminderData" status="complete" @refresh="getReminder('complete')" />
						</template>
					</div>
				</v-tab-item>
			</v-tabs-items>
			<div class="d-flex align-center border-top">
				<div>{{ showing_string }}</div>
				<v-spacer></v-spacer>
				<div class="col-md-6">
					<v-pagination
						color="blue darken-4"
						v-model="current_page"
						:length="total_pages"
						total-visible="9"
						:disabled="pageLoading"
						v-on:input="updatePagination($event)"
					></v-pagination>
				</div>
			</div>
		</div>
		<CreateOrUpdateReminderV2
			v-if="open_dialog_reminder"
			:drawer="open_dialog_reminder"
			v-on:success="getReminder"
			v-on:close="closeDialog()"
		></CreateOrUpdateReminderV2>
		<Dialog :dialog="serviceDialog" :dialog-width="650">
			<template v-slot:body>
				<div class="">
					<!-- <img class="mb-4" :src="pico_barcode_imgae" :alt="pico_barcode_text" width="334px" />-->
					<h3 class="primary--text text-capitalize">
						Reminder Description
						<!-- <span style="color:black;">({{contractBarcode}})	</span> -->
					</h3>
					<TextAreaInput
						:rows="4"
						dense
						readonly
						ref="comment"
						:word-limit="200"
						hide-details
						v-model="reminderDescription"
						:disabled="pageLoading"
						:loading="pageLoading"
					></TextAreaInput>
				</div>
			</template>

			<template v-slot:action>
				<v-btn depressed tile v-on:click="serviceDialog = false">Close </v-btn>
			</template>
		</Dialog>
		<BulkReminderCompleteTemplate
			type="Reminder"
			completeUrl="reminders-complete"
			:completeDialog="complete_dialog"
			@close="complete_dialog = false"
			@success="(complete_dialog = false), successCompleted()"
			:ids="selectedIds"
		></BulkReminderCompleteTemplate>
		<!-- 	<v-row class="my-2">
			<v-col md="6" class="my-auto">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					v-on:input="updatePagination($event)"
				></v-pagination>
			</v-col>
		</v-row> -->
	</v-sheet>
</template>
<style>
.custome-table {
	background-color: #f3f6f9 !important;
	border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/*  border-bottom: solid 1px #bcc0c9 !important; */
}
</style>
<script>
/*
import ContractSoeUrl from "@/view/module/contract/Contract-Seo-Urls";
import Contact from "@/view/module/contract/Create-Contract";
import { SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
*/
import { mapGetters } from "vuex";
import { QUERY, PATCH, GET } from "@/core/services/store/request.module";
/*
	import { SET_TBODY } from "@/core/services/store/listing.module";
	import Dialog from "@/view/components/Dialog";
*/
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import CreateOrUpdateReminderV2 from "@/view/pages/leads/create/CreateOrUpdateReminderV2";
import DateRangePicker from "@/view/components/DateRangePickerV2";
// import Chip from "@/view/components/Chip";
import ReminderTable from "@/view/pages/leads/components/ReminderTable.vue";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PageSummeryBox from "@/view/components/PageSummeryBox";
import BulkReminderCompleteTemplate from "@/view/components/BulkReminderCompleteTemplate";

export default {
	name: "Seo-report-listing",
	title: "Listing Invoice",
	/* mixins: [ListingMixin], */
	data() {
		return {
			open_dialog_reminder: false,
			isReminderCreate: false,
			reminderSingleData: {},
			pageTitle: "Seo-report",
			pageBreadcrumbs: [{ text: "Seo-report", disabled: true }],
			endpoint: "seo-report",
			/* reminderTabs : 'overdue', */
			defaultFilter: {},
			filterType: [
				{ value: "today", text: "Today" },
				{ value: "today", text: "Today" },
				{ value: "this_week", text: "This Week" },
				{ value: "this_month", text: "This Month" },
				{ value: "this_year", text: "This Year" },
			],
			moduleType: [
				{ value: "lead", text: "Lead" },
				{ value: "task", text: "Task" },
				{ value: "meeting", text: "Meeting" },
				{ value: "customer", text: "Customer" },
			],
			count: {},
			date_status: Number(new Date()),
			status: "all",
			module_type: null,
			date_type: null,
			summary_show: true,
			pageLoading: false,
			customerServiceId: 0,
			urlsDialog: false,
			reminderDescription: null,
			serviceDialog: false,
			listingStatus: [],
			date_range: [],
			reminderData: [],
			overDue_count: 0,
			upcoming_count: 0,
			complete_count: 0,
			customerCount: 0,
			updateDialog: false,
			contactId: 0,
			DataSearch: null,
			current_page: 1,
			total_pages: 5,
			showing_string: null,
			typeColor: null,
			totalSelected: 0,
			paginationPageSize: 0,
			selectedIds: [],
			complete_dialog: false,
		};
	},
	props: {},
	watch: {},
	methods: {
		changeTab(event) {
			const query = this.$route.query;
			if (event == "overdue" && query && query.tab != "overdue") {
				this.filterReminder(query.tab);
			} else {
				this.filterReminder(event);
			}
			this.selectedIds = [];
		},
		completeReminders() {
			this.complete_dialog = true;
		},
		selectedData(ids) {
			this.selectedIds = ids;
		},
		open_reminder_drawer() {
			this.isReminderCreate = true;
			this.open_dialog_reminder = true;
		},
		closeDialog() {
			this.open_dialog_reminder = false;
		},
		openPopup(data) {
			this.reminderDescription = data.mobile_description;
			this.serviceDialog = true;
			return false;
		},
		onEsc() {
			this.resetSearch();
		},
		clearFilter() {
			this.DataSearch = null;
			this.module_type = null;
			this.date_type = null;
			this.date_range = [];
			this.date_status = Number(new Date());
			this.getReminder(this.$route.query.tab);
		},
		isFilterApply() {
			if (this.DataSearch || this.module_type || this.date_type) {
				return true;
			}
			if (this.date_range && Array.isArray(this.date_range) && this.date_range.length) {
				return true;
			}
			return false;
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.DataSearch = null;
				this.getReminder(this.$route.query.tab);
			});
		},
		relatedPage(row) {
			let id = row.related_id;
			let routeName = "";
			if (row.related_to == "lead") {
				routeName = "leads-detail";
			}
			if (row.related_to == "task") {
				routeName = "task-detail";
			}
			if (row.related_to == "meeting") {
				routeName = "meeting-detail";
			}
			if (row.related_to == "customer") {
				routeName = "customer-detail2";
			}
			this.$router.push({
				name: routeName,
				params: { id },
				query: { t: new Date().getTime() },
			});
		},
		updateReminder(remind) {
			if (remind.activated == true) {
				remind.activated = 1;
			} else {
				remind.activated = 0;
			}
			this.$store
				.dispatch(PATCH, {
					url: `status-reminder/${remind.id}`,
					data: {
						activated: remind.activated,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder update successfully." },
					]);
					this.$emit("reminderSuccess", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getReminderType(data) {
			if (data == 1) {
				this.typeColor = "cyan";
				return "One Time Reminder";
			}
			if (data == 2) {
				this.typeColor = "green";
				return "Daily Reminder";
			}
			if (data == 3) {
				this.typeColor = "blue";
				return "Weekly Reminder";
			}
			if (data == 4) {
				this.typeColor = "orange";
				return "Monthly Reminder";
			}
			if (data == 5) {
				this.typeColor = "yellow";
				return "Quarterly Reminder";
			}
			if (data == 6) {
				this.typeColor = "yellow";
				return "Half Yearly Reminder";
			}
		},
		getActiveColor(data) {
			if (data == 1) {
				return "cyan";
			}
			if (data == 2) {
				return "green";
			}
			if (data == 3) {
				return "blue";
			}
			if (data == 4) {
				return "orange";
			}
			if (data == 5) {
				return "pink";
			}
			if (data == 6) {
				return "pink";
			}
		},
		filterByTab(status) {
			this.current_page = 1;
			this.getReminder(status);
		},
		filterReminder(tabReminder = "") {
			this.current_page = 1;
			this.getReminder(tabReminder);
			//console.log(tabReminder);
			if (tabReminder) this.reminderTabs = tabReminder;
		},
		successCompleted() {
			if (
				Array.isArray(this.reminderData) &&
				(this.reminderData.length == this.selectedIds.length || this.reminderData.length == 1) &&
				this.current_page > 1
			) {
				this.current_page -= 1;
				const { name, params, query } = this.$route;
				this.$router.push({ name, params, query: { ...query, page: this.current_page - 1 } });
			}
			this.selectedIds = [];
			this.getReminder();
		},
		getReminder(data = this.reminderTabs) {
			let status = 1;
			if (data == "overdue") {
				status = 1;
			}
			if (data == "upcoming") {
				status = 2;
			}
			if (data == "complete") {
				status = 3;
			}
			this.pageLoading = true;

			let payload = {
				all: 1,
				status: status,
				date_type: this.date_type,
				filter_type: this.module_type,
				date_range: this.date_range,
				search: this.DataSearch,
				page: this.current_page,
				per_page: 15,
			};
			this.setQuery(payload);
			this.$store
				.dispatch(QUERY, {
					url: `reminder`,
					data: { ...payload },
				})
				.then((data) => {
					this.reminderData = data.rows;
					this.overDue_count = data.countings.overdue_reminders_count;
					this.upcoming_count = data.countings.upcoming_reminders_count;
					this.complete_count = data.countings.completed_reminders_count;
					/* SET Pagination */
					this.current_page = data?.current_page;
					this.total_pages = data?.total_page;
					this.showing_string = data?.showing_string;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		setQuery(payload) {
			this.$router.push({
				name: "all-reminder",
				query: {
					...payload,
					tab: this.reminderTabs,
					t: new Date().getTime(),
				},
			});
		},
		dateFilterRows(data) {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `reminder`,
					data: {
						all: 1,
						status: data == "overdue" ? 1 : data == "upcoming" ? 2 : 3,
						date_type: this.date_type,
						filter_type: this.module_type,
						date_range: this.date_range,
						search: this.DataSearch,
					},
				})
				.then((data) => {
					this.reminderData = data.rows;
					/* this.$store.commit(SET_TBODY, data.rows); */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		reminderCount() {
			this.$store
				.dispatch(GET, {
					url: `reminder-count`,
				})
				.then((data) => {
					(this.overDue_count = data.overDue_count), (this.upcoming_count = data.upcoming_count);
					this.complete_count = data.complate_count;
					/* 	(this.customerCount = data.customer_count); */
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		updatePagination(param) {
			console.log({ param });
			// if (this.current_page == param) {
			// 	return false;
			// }
			this.$nextTick(() => {
				this.getReminder(this.reminderTabs);
			});
		},
	},
	components: {
		/*  ShowValue, */
		// Chip,
		Dialog,
		CreateOrUpdateReminderV2,
		TextAreaInput,
		DateRangePicker,
		PageSummeryBox,
		ReminderTable,
		BulkReminderCompleteTemplate,
		/* ContractSoeUrl,
		Dialog,
		Contact, */
	},
	mounted() {
		this.getReminder(this.reminderTabs);
		this.$store.dispatch(SET_BREADCRUMB, [
			// { text: "Reminder", disabled: true, href: "" },
			// { text: "Reminder", disabled: true, href: "" },
			// { text: "Reminder", disabled: true },
		]);
		// this.reminderCount();

		/* console.log(this.showing_string,'sjdfshgf.fhsjhdajkshdjas')
		this.$store.commit(SET_SHOWING_STRING, this.showing_string); */
	},
	beforeMount() {
		const query = this.$route.query;
		this.date_type = query.date_type || null;
		this.module_type = query.filter_type || null;
		this.date_range = query.date_range || [];
		this.DataSearch = query.search || null;
		this.current_page = +query.page || 1;
		this.reminderTabs = query.tab || "overdue";
	},
	computed: {
		...mapGetters(["selected", "tbody", "currentPage", "totalPage", "showingString"]),
		reminderTabs: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.reminderTabs) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "reminder";
			},
		},
	},
};
</script>
