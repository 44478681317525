<template>
	<div>
		<table width="100%" class="detail-table table-head-sticky">
			<thead>
				<tr>
					<th v-if="status != 'complete'" class="p-2 light-blue-bg text-center custome-table" width="5%">
						<v-checkbox
							dense
							v-model="checkAll"
							:ripple="false"
							hide-details
							color="blue darken-4"
							class="m-0 p-0"
						></v-checkbox>
					</th>
					<th class="p-2 light-blue-bg text-center custome-table" width="10%">Action</th>
					<th class="p-2 light-blue-bg custome-table" width="10%">Reminder #</th>
					<th class="p-2 light-blue-bg custome-table" width="15%">Title</th>
					<th class="p-2 light-blue-bg custome-table" width="15%">Reminder Date</th>
					<!-- <th class="p-2 light-blue-bg custome-table" width="10%">Reminder Type</th> -->

					<th class="p-2 light-blue-bg custome-table" width="30%" v-if="false">Attendees</th>
					<th class="p-2 light-blue-bg custome-table" width="30%" style="max-width: 200px">
						Description
					</th>
				</tr>
			</thead>
			<tbody v-if="reminderData.length">
				<tr v-for="(row, index) in reminderData" :key="index">
					<td v-if="status != 'complete'" class="p-2 border-top-light-grey custome-table-td text-center">
						<v-checkbox
							dense
							:ripple="false"
							:value="row.id"
							hide-details
							v-model="selectedRows"
							multiple
							@change="selectRow"
							class="m-0 p-0"
							color="blue darken-4"
						></v-checkbox>
					</td>
					<td style="min-width: 9.9rem" class="p-2 border-top-light-grey custome-table-td text-center">
						<template v-if="status != 'complete'">
							<v-tooltip right content-class="custom-right-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										depressed
										fab
										dark
										x-small
										color="green"
										class="mr-3 custom-disabled"
										@click="completeReminder(row)"
									>
										<v-icon>mdi-check</v-icon>
									</v-btn>
								</template>
								<span>Mark as Completed</span>
							</v-tooltip>
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										fab
										dark
										x-small
										color="blue"
										v-bind="attrs"
										v-on="on"
										class="mr-3 custom-disabled"
										@click="updateReminder(row)"
									>
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
								<span>Edit</span>
							</v-tooltip>
						</template>
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									fab
									dark
									x-small
									color="red"
									v-bind="attrs"
									v-on="on"
									class="mr-3 custom-disabled"
									@click="deleteReminder(row)"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</td>
					<td class="p-2 border-top-light-grey custome-table-td">
						<div class="d-flex">
							<Chip :text="row.barcode" color="cyan" v-if="row.barcode"></Chip>
							<!-- <span class="ml-2">
                                <div v-if="row.callType == 'call'">
                                    <v-badge color="green" content="C"></v-badge>
                                </div>
                                <div v-if="row.callType == 'email'">
                                    <v-badge color="red" content="E"></v-badge>
                                </div>
                                <div v-if="row.callType == 'other'">
                                    <v-badge color="cyan" content="O"></v-badge>
                                </div>
                            </span> -->
							<span class="ml-2">
								<div v-if="row.callType == 'call'">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs">
												<v-icon color="green">mdi-phone</v-icon>
											</div>
										</template>
										<span>Call</span>
									</v-tooltip>
								</div>
								<div v-if="row.callType == 'email'">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs">
												<v-icon color="red">mdi-email</v-icon>
											</div>
										</template>
										<span>Email</span>
									</v-tooltip>
								</div>
								<div v-if="row.callType == 'other'">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs">
												<v-avatar color="cyan" size="24">
													<span class="white--text">O</span>
												</v-avatar>
											</div>
											<!-- <v-badge v-on="on" v-bind="attrs" color="cyan" content="O"></v-badge> -->
										</template>
										<span>Other</span>
									</v-tooltip>
								</div>
							</span>
						</div>
					</td>
					<td class="p-2 border-top-light-grey custome-table-td">
						<div class="d-flex">
							<div>
								<span v-if="row && row.title" class="mb-2">
									{{ row.title }}
								</span>
								<em class="text-muted" v-else>no title</em>
							</div>
							<v-spacer></v-spacer>
							<span class="ml-2">
								<div v-if="row.related_to == 'task'">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs">
												<v-avatar color="green" size="20">
													<span class="white--text">T</span>
												</v-avatar>
											</div>
										</template>
										<span>Task</span>
									</v-tooltip>
								</div>
								<div v-if="row.related_to == 'lead'">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs">
												<v-avatar color="green" size="20">
													<span class="white--text">L</span>
												</v-avatar>
											</div>
										</template>
										<span>Lead</span>
									</v-tooltip>
									<!-- <v-badge color="green" content="L"></v-badge> -->
								</div>
								<div v-if="row.related_to == 'meeting'">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs">
												<v-avatar color="red" size="20">
													<span class="white--text">M</span>
												</v-avatar>
											</div>
										</template>
										<span>Meeting</span>
									</v-tooltip>
									<!-- <v-badge color="red" content="M"></v-badge> -->
								</div>
								<div v-if="row.related_to == 'customer'">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<div v-on="on" v-bind="attrs">
												<v-avatar color="orange" size="20">
													<span class="white--text">C</span>
												</v-avatar>
											</div>
										</template>
										<span>Customer</span>
									</v-tooltip>
								</div>
							</span>
						</div>
						<div
							v-if="row.related_to == 'task'"
							class="cursor-pointer"
							v-on:click.stop.prevent="relatedPage(row)"
						>
							<Chip
								:text="row.task_barcode"
								small
								color="blue"
								text-color=""
								outlined
								v-if="row.task_barcode"
							></Chip>
						</div>
						<div
							v-if="row.related_to == 'lead'"
							class="cursor-pointer"
							v-on:click.stop.prevent="relatedPage(row)"
						>
							<Chip
								:text="row.lead_barcode"
								small
								color="green"
								text-color=""
								outlined
								v-if="row.lead_barcode"
							></Chip>
						</div>
						<div
							v-if="row.related_to == 'meeting'"
							class="cursor-pointer"
							v-on:click.stop.prevent="relatedPage(row)"
						>
							<Chip
								:text="row.meeting_barcode"
								small
								color="red"
								text-color=""
								outlined
								v-if="row.meeting_barcode"
							></Chip>
						</div>
						<div
							v-if="row.related_to == 'customer'"
							class="cursor-pointer"
							v-on:click.stop.prevent="relatedPage(row)"
						>
							<Chip
								:text="row.customer_barcode"
								small
								color="orange"
								text-color=""
								outlined
								v-if="row.customer_barcode"
							></Chip>
						</div>
					</td>
					<td class="p-2 border-top-light-grey custome-table-td">
						<div class="mb-2">
							<!-- <v-badge color="green" content="S"></v-badge>  -->
							<!-- <span v-if="row" class="mr-3"
								>
								<v-icon size="25">
									mdi-calendar</v-icon
								></span
							> -->
							<span v-if="row && row.start_date">
								<Chip
									:text="formatDateTime(row.start_date)"
									text-color=""
									outlined
									small
									:color="getStatusColor()"
									v-if="row.start_date"
								></Chip>
								<!-- {{ formatDate(row.start_date) }} -->
							</span>
						</div>
						<div v-if="row && row.type">
							<span class="ml-0">
								<Chip
									:text="getReminderType(row.type)"
									text-color=""
									outlined
									:color="getActiveColor(row.type)"
									v-if="getReminderType(row.type)"
								></Chip>
							</span>
						</div>
						<!--    <div>
                                        <span v-if="row" class="mr-5"><v-badge color="red" content="E"></v-badge> :</span> 
                                        <span v-if="row && row.end_date">{{ formatDate(row.end_date) }}</span>
                                    </div>
                                -->
					</td>
					<td class="p-2 border-top-light-grey custome-table-td" v-if="false">
						<template v-if="row && row.attendies">
							<v-chip left small v-for="(staff, index) in row.attendies" :key="index" class="mr-2 my-1">
								<v-avatar left small>
									<img v-if="staff.image" :src="staff.image" />
									<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
								</v-avatar>
								<span class="fw-500"> {{ staff.display_name }}</span>
							</v-chip>
						</template>
					</td>
					<td class="p-2 border-top-light-grey custome-table-td">
						<span v-if="row && row.description" class="d-flex">
							<div class="" v-html="row.description"></div>
							<!-- <span class="text-primary ml-1 cursor-pointer" v-on:click.stop.prevent="openPopup(row)">Read more </span> -->
						</span>
						<em v-else class="text-muted"> no description</em>
					</td>
				</tr>
			</tbody>
			<tfoot v-else>
				<tr>
					<td colspan="7">
						<p class="m-0 row-not-found text-center py-3">
							<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
							Uhh... There <span class="text-lowercase">are no reminders at the moment.</span>
						</p>
					</td>
				</tr>
			</tfoot>
		</table>
		<CreateOrUpdateReminderV2
			v-if="open_dialog_reminder"
			:drawer="open_dialog_reminder"
			:reminderId="editReminderId"
			v-on:success="refreshReminder"
			v-on:close="closeReminderDialog"
		></CreateOrUpdateReminderV2>
		<!-- v-on:deleteSuccess="deleteRefreshReminder($event)" -->
		<DeleteTemplate
			type="Reminder"
			:deleteUrl="deleteUrl"
			:deleteText="deleteText"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="(confirm_dialog = false), refreshReminder()"
		></DeleteTemplate>
		<ReminderCompleteTemplate
			type="Reminder"
			:completeUrl="completeURL"
			:completeText="completeTEXT"
			:completeDialog="complete_dialog"
			v-on:close="complete_dialog = false"
			v-on:success="(complete_dialog = false), refreshReminder()"
			:dataObject="selectedReminder"
		></ReminderCompleteTemplate>
	</div>
</template>
<script>
import Chip from "@/view/components/Chip";
import CreateOrUpdateReminderV2 from "@/view/pages/leads/create/CreateOrUpdateReminderV2";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ReminderCompleteTemplate from "@/view/components/ReminderCompleteTemplate";
// import { GET } from "@/core/services/store/request.module";
export default {
	name: "ReminderTable",
	props: {
		data: {
			type: Array,
			default: () => {
				return [];
			},
		},
		status: {
			type: String,
		},
		selectedIds: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	watch: {
		data: {
			deep: true,
			immediate: true,
			handler(param) {
				this.reminderData = param;
			},
		},
		selectedIds: {
			deep: true,
			immediate: true,
			handler(param) {
				this.selectedRows = param;
			},
		},
	},
	data() {
		return {
			selectedReminder: {},
			reminderData: [],
			editReminderId: 0,
			open_dialog_reminder: false,
			relatedId: 0,
			relatedTo: "lead",
			meeting_title: null,

			confirm_dialog: false,
			deleteUrl: null,
			deleteText: null,
			selectedRows: [],
			complete_dialog: false,
			completeURL: null,
			completeTEXT: null,
		};
	},
	methods: {
		selectRow() {
			this.$emit("selection", this.selectedRows);
		},
		getStatusColor() {
			let color = "blue";
			if (this.status == "complete") {
				color = "green";
			} else if (this.status == "overdue") {
				color = "red";
			}
			return color;
		},
		getReminderType(data) {
			if (data == 1) {
				this.typeColor = "cyan";
				return "One Time Reminder";
			}
			if (data == 2) {
				this.typeColor = "green";
				return "Daily Reminder";
			}
			if (data == 3) {
				this.typeColor = "blue";
				return "Weekly Reminder";
			}
			if (data == 4) {
				this.typeColor = "orange";
				return "Monthly Reminder";
			}
			if (data == 5) {
				this.typeColor = "yellow";
				return "Quarterly Reminder";
			}
			if (data == 6) {
				this.typeColor = "yellow";
				return "Half Yearly Reminder";
			}
		},
		getActiveColor(data) {
			if (data == 1) {
				return "cyan";
			}
			if (data == 2) {
				return "green";
			}
			if (data == 3) {
				return "blue";
			}
			if (data == 4) {
				return "orange";
			}
			if (data == 5) {
				return "pink";
			}
			if (data == 6) {
				return "pink";
			}
		},
		refreshReminder() {
			this.$emit("refresh", true);
		},
		closeReminderDialog() {
			this.editReminderId = 0;
			this.open_dialog_reminder = false;
		},
		updateReminder(data) {
			this.editReminderId = data.id;
			this.open_dialog_reminder = true;
		},
		completeReminder(data) {
			this.selectedReminder = data;
			this.completeTEXT = `(${data.barcode}) ` + (data.title ? data.title : "");
			this.completeURL = `reminder-complete/${data.id}`;
			this.complete_dialog = true;
		},
		deleteReminder(data) {
			this.selectedReminder = data;
			this.deleteText = `(${data.barcode}) ` + data.title;
			this.deleteUrl = `reminder/${data.id}`;
			this.confirm_dialog = true;
		},
	},
	components: {
		Chip,
		CreateOrUpdateReminderV2,
		DeleteTemplate,
		ReminderCompleteTemplate,
	},
	computed: {
		checkAll: {
			get() {
				return this.reminderData.length > 0
					? this.selectedRows.length == this.reminderData.length
						? true
						: false
					: false;
			},
			set(value) {
				if (value) {
					let _selectedRows = this.reminderData.map((v) => v.id);
					this.selectedRows = [..._selectedRows];
				} else {
					this.selectedRows = [];
				}
				this.$emit("selection", this.selectedRows);
			},
		},
	},
};
</script>
