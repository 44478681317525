<template>
	<div class="topbar-item">
		<div
			class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
			style="height: calc(1.5em + 1.65rem)"
			id="kt_quick_user_toggle"
		>
			<span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"> Hi, </span>
			<span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
				{{ getProfile.first_name }}
			</span>
			<span class="symbol symbol-30 symbol-light-success">
				<img v-if="false" alt="Pic" :src="getProfile.profile_img" />
				<span v-if="getProfile.first_name && true" class="symbol-label font-size-h5 font-weight-bold">
					{{ getProfile.first_name.slice(0, 1).toUpperCase() }}
				</span>
			</span>
		</div>

		<div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10 cursor-pointer">
			<!--begin::Header-->
			<!-- v-on:click="pushToRoute(getProfile.id)" -->
			<div
				class="offcanvas-header d-flex align-items-center justify-content-between pb-5 cursor-poniter"
			>
				<h3 class="font-weight-bold m-0">
					User Profile
					<!--small class="text-muted font-size-sm ml-2">12 messages</small-->
				</h3>
				<a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
					<i class="ki ki-close icon-xs text-muted"></i>
				</a>
			</div>
			<!--end::Header-->

			<!--begin::Content-->
			<perfect-scrollbar
				class="offcanvas-content pr-5 mr-n5 scroll"
				style="max-height: 90vh; position: relative"
			>
				<!--begin::Header-->
				<div class="d-flex align-items-center mt-5">
					<div v-on:click="pushToRoute(getProfile.id)" class="symbol symbol-100 mr-5">
						<img
							v-if="getProfile && getProfile.profile_img"
							class="symbol-label"
							:src="getProfile.profile_img"
							alt=""
						/>
						<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
						<i class="symbol-badge bg-success"></i>
					</div>
					<div class="d-flex flex-column">
						<span
							class="font-weight-bold font-size-h5 text-dark-75 text-primary"
							v-if="getProfile && getProfile.display_name"
						>
							{{ getProfile.display_name }}
						</span>
						<!-- <span class="text-muted" v-if="getProfile && getProfile.role" v-on:click="pushToRoute(getProfile.id)">
							{{ getProfile.role }}
						</span> -->
						<!-- <div class="text-muted mt-1">{{ getProfile.department }}</div> -->
						<div class="navi mt-2" v-on:click="pushToRoute(getProfile.id)">
							<a href="#" class="navi-item">
								<span class="navi-link p-0 pb-2">
									<span class="navi-icon mr-1">
										<span class="svg-icon svg-icon-lg svg-icon-primary">
											<!--begin::Svg Icon-->
											<inline-svg src="media/svg/icons/Communication/Mail-notification.svg" />
											<!--end::Svg Icon-->
										</span>
									</span>
									<span
										class="navi-text text-muted text-hover-primary"
										v-if="getProfile && getProfile.email"
									>
										{{ getProfile.email }}
									</span>
								</span>
							</a>
						</div>
						<button class="btn btn-light-primary btn-bold" @click="onLogout">Sign out</button>
					</div>
				</div>
				<!--end::Header-->
				<div class="separator separator-dashed mt-8 mb-5"></div>
				<!--begin::Nav-->
				<template>
					<div class="navi navi-spacer-x-0 p-0">
						<!--begin::Item-->
						<router-link
							:to="{ name: 'user-profile', params: { id: getProfile.id }, query: { tab: 'overall' } }"
							@click.native="closeOffcanvas"
							href="#"
							class="navi-item"
						>
							<div class="navi-link">
								<div class="symbol symbol-40 bg-light mr-3">
									<div class="symbol-label">
										<span class="svg-icon svg-icon-md svg-icon-warning">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/svg/icons/General/Notification2.svg')" />
											<!--end::Svg Icon-->
										</span>
									</div>
								</div>
								<div class="navi-text">
									<div class="font-weight-bold">My Account</div>
									<div class="text-muted">Manage my account</div>
								</div>
							</div>
						</router-link>
						<!--end:Item-->
						<!--begin::Item-->
						<router-link
							to="/users"
							@click.native="closeOffcanvas"
							href="#"
							class="navi-item"
							v-if="
								getPermission('user:view') &&
								AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
							"
						>
							<div class="navi-link">
								<div class="symbol symbol-40 bg-light mr-3">
									<div class="symbol-label">
										<span class="svg-icon svg-icon-md svg-icon-warning">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/svg/avatars/001-boy.svg')" />
											<!--end::Svg Icon-->
										</span>
									</div>
								</div>
								<router-link to="/users">
									<div class="navi-text">
										<div class="font-weight-bold">Users</div>
										<div class="text-muted">Manage all users</div>
									</div>
								</router-link>
							</div>
						</router-link>
						<!--end:Item-->
						<!--begin::Item-->
						<router-link to="/setting" @click.native="closeOffcanvas" href="#" class="navi-item">
							<div class="navi-link">
								<div class="symbol symbol-40 bg-light mr-3">
									<div class="symbol-label">
										<span class="svg-icon svg-icon-md svg-icon-danger">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/svg/icons/Files/Selected-file.svg')" />
											<!--end::Svg Icon-->
										</span>
									</div>
								</div>

								<router-link to="/setting?tab=personal_setting">
									<div class="navi-text">
										<div class="font-weight-bold">Setting</div>
										<div class="text-muted">
											settings and more
											<span class="label label-light-danger label-inline font-weight-bold"> update </span>
										</div>
									</div>
								</router-link>
							</div>
						</router-link>
						<!--end:Item-->
					</div>
					<!--end::Nav-->
					<div class="separator separator-dashed my-7"></div>
					<!--begin::Notifications-->
					<div v-if="false">
						<!--begin:Heading-->
						<h5 class="mb-5">Recent Notifications</h5>
						<!--end:Heading-->
						<template v-for="(item, i) in list">
							<!--begin::Item -->
							<div
								class="d-flex align-items-center rounded p-5 gutter-b"
								v-bind:class="`bg-light-${item.type}`"
								v-bind:key="i"
							>
								<span class="svg-icon mr-5" v-bind:class="`svg-icon-${item.type}`">
									<span class="svg-icon svg-icon-lg">
										<!--begin::Svg Icon-->
										<inline-svg :src="item.svg" />
										<!--end::Svg Icon-->
									</span>
								</span>
								<div class="d-flex flex-column flex-grow-1 mr-2">
									<a href="#" class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">
										{{ item.title }}
									</a>
									<span class="text-muted font-size-sm">
										{{ item.desc }}
									</span>
								</div>
								<span class="font-weight-bolder py-1 font-size-lg" v-bind:class="`text-${item.type}`">
									{{ item.alt }}
								</span>
							</div>
							<!--end::Item -->
						</template>
					</div>
					<!--end::Notifications-->
				</template>
			</perfect-scrollbar>
			<!--end::Content-->
		</div>
	</div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
	overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { GET_PROFILE } from "@/core/services/store/profile.module";

export default {
	name: "KTQuickUser",
	data() {
		return {
			list: [
				{
					title: "Another purpose persuade",
					desc: "Due in 2 Days",
					alt: "+28%",
					svg: "media/svg/icons/Home/Library.svg",
					type: "warning",
				},
				{
					title: "Would be to people",
					desc: "Due in 2 Days",
					alt: "+50%",
					svg: "media/svg/icons/Communication/Write.svg",
					type: "success",
				},
				{
					title: "Purpose would be to persuade",
					desc: "Due in 2 Days",
					alt: "-27%",
					svg: "media/svg/icons/Communication/Group-chat.svg",
					type: "danger",
				},
				{
					title: "The best product",
					desc: "Due in 2 Days",
					alt: "+8%",
					svg: "media/svg/icons/General/Attachment2.svg",
					type: "info",
				},
			],
		};
	},
	mounted() {
		// Init Quick User Panel
		this.getUserProfile();
		KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
	},
	methods: {
		pushToRoute(id) {
			this.$router.push({
				name: "user-profile",
				params: { id },
				query: {
					t: +new Date(),
				},
			});
		},
		getUserProfile() {
			this.$store.dispatch(GET_PROFILE).then(() => {
				this.setAsideStateSetting();
			});
		},
		onLogout() {
			this.$store.dispatch(LOGOUT).then(() => {
				let userType = localStorage.getItem("user-type");
				if (userType) {
					this.$router.push({ name: `login-${userType}` });
				} else {
					this.$router.push({ name: "login-sales" });
				}
			});
		},
		closeOffcanvas() {
			new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
		},
		setAsideStateSetting() {
			const body = document.querySelector("body");
			if (this.getProfile.side_menu) {
				body.classList.remove("aside-minimize");
			} else {
				body.classList.add("aside-minimize");
			}
		},
	},
	computed: {
		...mapGetters(["getProfile"]),

		getFullName() {
			return "";
		},
	},
};
</script>
